<template>
  <div class="px-5">
    <div class="hidden-sm-and-down">
      <v-row>
        <v-col cols="6">
          <div class="text-h4 primary--text">รายงานการขายแยกตามพนักงาน</div>
        </v-col>
        <v-col cols="4"></v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            color="success"
            small
            outlined
            class="ml-5 px-10"
            @click="downloadItemExcel()"
          >
            ดาวน์โหลด <v-icon right small>mdi-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-toolbar dense flat rounded outlined class="my-8 pt-3 pb-15">
        <v-row>
          <v-col cols="2">
            <v-select
              label="ชื่อพนักงาน"
              :items="user_list_filter"
              item-value="_id"
              item-text="name"
              outlined
              rounded
              dense
              hide-details
              v-model="filter.user_id"
            >
            </v-select>
          </v-col>

          <v-col cols="2">
            <v-select
              label="ช่องทางชำระ"
              :items="paymentTypeList"
              item-value="id"
              item-text="name"
              outlined
              rounded
              dense
              hide-details
              v-model="filter.payment_type"
            >
            </v-select>
          </v-col>

          <v-col cols="2">
            <v-select
              label="ช่วงเวลา"
              v-model="timeRangeType"
              :items="timeRangeTypes"
              outlined
              rounded
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="2">
            <DatePicker
              v-if="timeRangeType == 1"
              label="วันจดทะเบียน"
              :value="filter.start_date"
              @onChange="(val) => (filter.start_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />

            <v-menu
              v-else-if="timeRangeType == 2"
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="filter.start_date ? getMonth(filter.start_date) : ''"
                  label="เดือน"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  hideDetails
                  outlined
                  rounded
                  dense
                  readonly
                >
                </v-text-field>
              </template>
              <v-date-picker
                class="hidden-sm-and-down"
                v-model="filter.month"
                type="month"
                no-title
                scrollable
                locale="th"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(filter.month), changeMonth()"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="2">
            <DatePicker
              v-if="timeRangeType == 1"
              label="ถึงวันที่"
              :value="filter.end_date"
              @onChange="(val) => (filter.end_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />
          </v-col>

          <v-col cols="2">
            <v-btn
              color="primary"
              small
              class="float-right"
              @click="getEmployeeReportData()"
            >
              แสดงรายงาน <v-icon right>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-row
        class="pl-10 pr-10 ml-0 mr-0 fz-1 grey lighten-4 elevation-2 d-flex justify-space-between"
        style="border-radius: 5px 5px 0px 0px"
      >
        <v-col class="report-headers" cols="1">วันที่</v-col>
        <v-col class="report-headers" cols="1">เลขที่เอกสาร</v-col>
        <v-col class="report-headers" cols="1">ชื่อลูกค้า</v-col>
        <v-col class="report-headers" cols="1">โปรเจ็ค</v-col>
        <v-col class="report-headers" cols="1">ตรวจสภาพ</v-col>
        <v-col class="report-headers" cols="1">พรบ</v-col>
        <v-col class="report-headers" cols="1">ภาษี</v-col>
        <v-col class="report-headers" cols="1">ประกัน</v-col>
        <v-col class="report-headers" cols="1">อื่น ๆ</v-col>
        <v-col class="report-headers" cols="1">ยอดรวมสุทธิ</v-col>
        <v-col class="report-headers" cols="1">ธนาคาร</v-col>
      </v-row>

      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(item, i) in employee_list" :key="i">
          <v-expansion-panel-header
            class="primary--text text-h6"
            @click="closeAllPanels()"
          >
            {{ item.name }} (จำนวนลูกค้าที่ขายได้ {{ item.count }} คน)
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels v-model="openedPanel" accordion flat>
              <v-expansion-panel v-for="types in paymentList" :key="types.id">
                <v-expansion-panel-header
                  class="primary--text px-0 mx-0"
                  v-if="item[types.id].count != 0"
                  @click="loadItem(item._id, types.id)"
                >
                  <v-row class="d-flex justify-space-between pl-5">
                    <v-col cols="4" class="mr-10">
                      {{ types.name }} (จำนวน {{ item[types.id].count }} คน)
                    </v-col>
                    <v-col cols="1">{{
                      item[types.id].inspection_price | numeral('0,0.00')
                    }}</v-col>
                    <v-col cols="1">{{
                      item[types.id].third_insurance_price | numeral('0,0.00')
                    }}</v-col>
                    <v-col cols="1">{{
                      item[types.id].tax_price | numeral('0,0.00')
                    }}</v-col>
                    <v-col cols="1">{{
                      item[types.id].insurance_price | numeral('0,0.00')
                    }}</v-col>
                    <v-col cols="1">{{
                      item[types.id].other_price | numeral('0,0.00')
                    }}</v-col>
                    <v-col cols="1">{{
                      (Math.floor(
                        (item[types.id].net_price + Number.EPSILON) * 100
                      ) /
                        100)
                        | numeral('0,0.00')
                    }}</v-col>
                    <v-col cols="1"></v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-for="dt in payment_document_list" :key="dt.id">
                    <v-row class="d-flex justify-space-between">
                      <v-col cols="1">{{ dt.date_of_issue }}</v-col>
                      <v-col cols="1">{{ dt.number }}</v-col>
                      <v-col cols="1">{{ dt.customer_name }}</v-col>
                      <v-col cols="1">{{ dt.project_name }}</v-col>
                      <v-col cols="1">{{
                        dt.inspection_price | numeral('0,0.00')
                      }}</v-col>
                      <v-col cols="1">{{
                        dt.third_insurance_price | numeral('0,0.00')
                      }}</v-col>
                      <v-col cols="1">{{
                        dt.tax_price | numeral('0,0.00')
                      }}</v-col>
                      <v-col cols="1">{{
                        dt.insurance_price | numeral('0,0.00')
                      }}</v-col>
                      <v-col cols="1">{{
                        dt.other_price | numeral('0,0.00')
                      }}</v-col>
                      <v-col cols="1">{{
                        (Math.floor((dt.net_price + Number.EPSILON) * 100) /
                          100)
                          | numeral('0,0.00')
                      }}</v-col>
                      <v-col cols="1">{{ dt.payment_reference }}</v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div class="hidden-md-and-up">
      <v-row cols="12">
        <div class="text-h6 primary--text">รายงานการขายแยกตามพนักงาน</div>
      </v-row>
      <v-row justify="end" class="mb-3">
        <v-btn
          color="success"
          small
          outlined
          class="ml-5 px-10 float-right"
          @click="downloadItemExcel()"
        >
          ดาวน์โหลด <v-icon right small>mdi-download</v-icon>
        </v-btn>
      </v-row>
      <v-card outlined class="mb-5">
        <v-card-text>
          <v-row>
            <v-col cols="6" class="px-1">
              <v-select
                label="ชื่อพนักงาน"
                :items="user_list_filter"
                item-value="_id"
                item-text="name"
                outlined
                rounded
                dense
                hide-details
                v-model="filter.user_id"
              >
              </v-select>
            </v-col>

            <v-col cols="6" class="px-1">
              <v-select
                label="ช่องทางชำระ"
                :items="paymentTypeList"
                item-value="id"
                item-text="name"
                outlined
                rounded
                dense
                hide-details
                v-model="filter.payment_type"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="px-1">
              <v-select
                label="ช่วงเวลา"
                v-model="timeRangeType"
                :items="timeRangeTypes"
                outlined
                rounded
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-menu
                v-if="timeRangeType == 2"
                ref="menu2"
                v-model="menu2"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="
                      filter.start_date ? getMonth(filter.start_date) : ''
                    "
                    label="เดือน"
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    hideDetails
                    outlined
                    rounded
                    dense
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  class="hidden-md-and-up"
                  v-model="filter.month"
                  type="month"
                  no-title
                  scrollable
                  locale="th"
                  @change="$refs.menu.save(filter.month), changeMonth()"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row cols="6" v-if="timeRangeType == 1">
            <v-col class="px-0">
              <DatePicker
                label="วันจดทะเบียน"
                :value="filter.start_date"
                @onChange="(val) => (filter.start_date = val)"
                hideDetails
                outlined
                rounded
                dense
              />
            </v-col>

            <v-col class="px-0">
              <DatePicker
                label="ถึงวันที่"
                :value="filter.end_date"
                @onChange="(val) => (filter.end_date = val)"
                hideDetails
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                small
                class="float-right"
                @click="getEmployeeReportData()"
              >
                แสดงรายงาน <v-icon right>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-expansion-panels accordion class="mt-3">
        <v-expansion-panel v-for="(item, i) in employee_list" :key="i">
          <v-expansion-panel-header
            class="primary--text subtitle-1"
            @click="closeAllPanels()"
          >
            {{ item.name }} (จำนวนลูกค้าที่ขายได้ {{ item.count }} คน)
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-body-1">
            <v-expansion-panels v-model="openedPanel" accordion flat>
              <v-expansion-panel v-for="types in paymentList" :key="types.id">
                <v-expansion-panel-header
                  v-if="item[types.id].count != 0"
                  @click="loadItem(item._id, types.id)"
                  class="primary--text px-0 mx-0 text-body-2"
                >
                  <v-row class="d-flex justify-space-between pl-5">
                    <v-col cols="6" class="mr-5">
                      {{ types.name }} <br />
                      (จำนวน {{ item[types.id].count }} คน)
                    </v-col>
                    <v-col cols="5">
                      ยอดรวมสุทธิ <br />{{
                        (Math.floor(
                          (item[types.id].net_price + Number.EPSILON) * 100
                        ) /
                          100)
                          | numeral('0,0.00')
                      }}
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                    class="elevation-2 row-pointer text-body-2"
                    :headers="headers"
                    :items="payment_document_list"
                    :search="search"
                    :loading-text="$table_loading_text"
                    :items-per-page="item.count"
                    hide-default-footer
                    item-key="id"
                  >
                    <template v-slot:[`item.date_of_issue`]="{ item }">
                      {{ item.date_of_issue }}
                    </template>
                    <template v-slot:[`item.number`]="{ item }">
                      {{ item.number }}
                    </template>
                    <template v-slot:[`item.customer_name`]="{ item }">
                      {{ item.customer_name }}
                    </template>
                    <template v-slot:[`item.project_name`]="{ item }">
                      {{ item.project_name }}
                    </template>
                    <template v-slot:[`item.product_count`]="{ item }">
                      {{ item.product_count }}
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                      {{ item.price | numeral('0,0.00') }}
                    </template>
                    <template v-slot:[`item.discount`]="{ item }">
                      {{ item.discount | numeral('0,0.00') }}
                    </template>
                    <template v-slot:[`item.total`]="{ item }">
                      {{
                        (Math.floor((item.net_price + Number.EPSILON) * 100) /
                          100)
                          | numeral('0,0.00')
                      }}
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import * as moment from 'moment';
export default {
  components: {
    DatePicker,
  },
  data: () => ({
    filter: {
      user_id: '',
      payment_type: '',
      start_date: moment().add(-0, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      month: '',
    },
    menu: null,
    menu2: null,
    search: '',
    user_id: 0,
    payment_type: 0,
    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    list: [],
    employee_list: [],
    payment_document_list: [],
    user_list_filter: [],
    openedPanel: [],
    headers: [
      { text: 'วันที่', value: 'date_of_issue' },
      { text: 'เลขที่เอกสาร', value: 'number', filterable: false },
      { text: 'ชื่อลูกค้า', value: 'customer_name' },
      { text: 'โปรเจ็ค', value: 'project_name', filterable: false },
      { text: 'ตรวจสภาพ', value: 'inspection_price', filterable: false },
      { text: 'พรบ', value: 'third_insurance_price', filterable: false },
      { text: 'ภาษี', value: 'tax_price', filterable: false },
      { text: 'ประกัน', value: 'insurance_price', filterable: false },
      { text: 'อื่น ๆ', value: 'other_price', filterable: false },
      { text: 'ยอดรวมสุทธิ', value: 'total', filterable: false },
      { text: 'ธนาคาร', value: 'payment_reference', filterable: false },
    ],

    timeRangeType: 3,
    timeRangeTypes: [
      { value: 3, text: 'วันนี้' },
      { value: 0, text: 'เดือนปัจจุบัน' },
      { value: 2, text: 'เดือนก่อน' },
      { value: 1, text: 'เลือกช่วงวันที่' },
    ],
    paymentTypeList: [
      { id: '', name: 'ทั้งหมด' },
      { id: 1, name: 'เงินสด' },
      { id: 2, name: 'โอนเงิน' },
      { id: 3, name: 'บัตรเครดิต' },
      // {id: 'other', name: 'อื่น ๆ'},
    ],
    paymentList: [
      { id: 'payment_1', name: 'เงินสด' },
      { id: 'payment_2', name: 'โอนเงิน' },
      { id: 'payment_3', name: 'บัตรเครดิต' },
      // {id: 'other', name: 'อื่น ๆ'},
    ],
  }),

  created() {
    this.getUserList();
    this.getEmployeeReportData();
  },

  methods: {
    getMonth(date) {
      return moment(date).format('YYYY-MM');
    },
    changeMonth() {
      console.log(this.filter.month);
      this.filter.start_date = moment(this.filter.month, 'YYYY-MM')
        .startOf('month')
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss');
      this.filter.end_date = moment(this.filter.month, 'YYYY-MM')
        .endOf('month')
        .set({ hour: 23, minute: 59, second: 59 })
        .format('YYYY-MM-DDTHH:mm:ss');
    },
    async getUserList() {
      const startTime = performance.now();

      try {
        let filter = {
          branch_id: this.$store.state.selected_branch._id,
        };
        let body = {
          token: this.$jwt.sign(filter, this.$privateKey, {
            noTimestamp: true,
          }),
        };

        let res = await this.$axios.post(
          `${this.$baseUrl}/users/get_user`,
          body
        );
        console.log(res);
        let selectedBranchName = this.$store.state.selected_branch.branch_name;
        // Filter the users based on the selected branch name
        let filteredUsers = res.result.data.filter((user) =>
          user.permission.branchs.includes(selectedBranchName)
        );
        this.user_list_filter = [{ _id: '', name: 'ทั้งหมด' }].concat(
          filteredUsers
        );
      } catch (err) {
        console.error('Error in getUserList:', err);
        // Handle error as needed, e.g., show an error message to the user
      } finally {
        const endTime = performance.now();
        const duration = endTime - startTime;
        console.log(
          `getUserList completed in ${duration.toFixed(2)} milliseconds`
        );
      }
    },
    async getEmployeeReportData() {
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
        user_id: this.filter.user_id,
        payment_type: this.filter.payment_type,
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/report/report_by_employees`, body)
        .then(async (res) => {
          this.employee_list = res.result;
        });
      this.openedPanel = false;
    },
    async getEmployeePaymentData() {
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
        user_id: this.user_id,
        payment_type: this.payment_type,
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/report/report_by_employees_payment`, body)
        .then(async (res) => {
          this.payment_document_list = res.result;
          this.payment_document_list.forEach((item, i) => {
            this.payment_document_list[i].date_of_issue = this.formatDate(
              moment(item.date_of_issue, 'YYYY-MM-DDTHH:mm:ss')
            );
          });
        });
    },
    loadItem(user_id, payment_type) {
      this.user_id = user_id;
      this.payment_type = parseInt(payment_type[payment_type.length - 1]);
      this.getEmployeePaymentData();
    },
    formatDate(date) {
      if (!date) return '';
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    async downloadItemExcel() {
      this.loading = true;
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
        user_id: this.filter.user_id,
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      console.log(filter);
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/report/download_report_employee`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'ยอดขายพนักงาน_' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'ยอดขายพนักงาน_' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
    closeAllPanels() {
      this.openedPanel = null;
      console.log('openedPanel' + this.openedPanel);
    },
  },
  watch: {
    timeRangeType: function (value, oldValue) {
      if (value == 0) {
        // 0 = เดือนปัจจุบัน
        this.filter.start_date = moment()
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (value == 1) {
        // 1 = เลือกช่วงวันที่
        this.filter.start_date = moment()
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (value == 2) {
        // 2 = เดือนก่อน
        this.filter.start_date = moment()
          .subtract(1, 'months')
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .subtract(1, 'months')
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.month = moment().subtract(1, 'months').format('YYYY-MM');
      } else if (value == 3) {
        // 3 = วันนี้
        this.filter.start_date = moment()
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .set({ hour: 23, minute: 59, second: 59 })
          .format('YYYY-MM-DDTHH:mm:ss');
      }
    },
  },
};
</script>

<style>
.report-headers {
  font-size: 14px;
  font-weight: 200;
  color: gray;
}
</style>
